<script setup lang="ts">
import type { Product } from '~/type/product/Product'

interface Props {
  product: Product
}

const props = withDefaults(defineProps<Props>(), {})
const emit = defineEmits<{
  (event: 'open', value: { id: string; path?: string; status: boolean }): void
  (event: 'decrease'): void
}>()

const actionCart = useActionCart(props.product.id)
const { $emitter } = useNuxtApp()

function onClick({ id, path }: { id: string; path?: string }) {
  $emitter.emit('product-select', { ...props.product, listName: 'Корзина' })
  !props.product.isGift && emit('open', { id, path, status: true })
}
</script>

<template>
  <div class="cart-product" :class="[{ 'cart-product_not-available': !product.price }]">
    <div class="cart-product__photo-wrapper link link_wu" @click="onClick({ id: product.id, path: product.path })">
      <ImgLazy
        :img-path="product.thumbnail.x1 ? product.thumbnail.x1 : product.image"
        :srcset="product.thumbnail.x2 ? product.thumbnail.x2 : product.image"
        :alt="product.name"
        class="cart-product__photo"
      />
      <span v-if="product.isGift" class="cart-product__gift">В подарок</span>
    </div>
    <div class="cart-product__info">
      <div class="cart-product__title p" :class="[{ 'cart-product__title_unavailable': product.limit === 0 }]">
        <span
          :class="{ 'link link_wu': !product.isGift }"
          :title="product.name"
          @click="onClick({ id: product.id, path: product.path })"
        >
          {{ product.name }}
        </span>
      </div>
      <template v-if="product.limit">
        <!-- ждем данные с апи -->
        <!-- <span class="cart-product__specifications mb-5 fz-11 lh-16 grey-text">300 г. в 1 шт</span> -->
        <span class="cart-product__price">
          <Price
            v-if="product.price && !product.isGift"
            :price="product.price"
            size="big"
          />
        </span>
      </template>
      <div v-else class="cart-product__bottom cart-product__bottom_unavailable">
        <span class="red-text link link_wu mr-3">Нет в наличии</span>
      </div>
    </div>
    <div v-if="product.limit && !product.isGift" class="cart-product__bottom">
      <div class="cart-product__count">
        <Counter
          :quantity="actionCart.quantity.value"
          :max="product?.limit"
          :loading="actionCart.loading.value"
          @decrease="emit('decrease'), actionCart.decreaseAmount()"
          @increase="actionCart.increaseAmount({ listName: 'Корзина' })"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.cart-product {
  display: flex;
  align-items: center;

  &_not-available {

    ^&__photo-wrapper,
    ^&__title {
      opacity: 0.3;
    }
  }

  &__gift {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 19px;
    font-size: 10px;
    line-height: 11px;
    color: #fff;
    background-color: #FC9A26;
  }

  &__photo-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 84px;
    height: 84px;
    margin-right: 12px;
    overflow: hidden;
    background-color: #f8f8f8;
    border-radius: 15px;
  }

  &__photo {
    max-width: 66px;
    max-height: 66px;
    mix-blend-mode: multiply;
  }

  &__info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__title {
    display: -webkit-box;
    max-height: 54px;
    margin-right: 8px;
    margin-bottom: 12px;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &_unavailable {
      opacity: 0.4;
    }
  }

  &__price {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
  }

  &__bottom {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    margin-left: auto;

    &_unavailable {
      flex-direction: column;
      align-items: flex-start;
      margin-left: initial;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__count {
    flex-shrink: 0;
    min-width: 77px;
    padding: 12px 15px;
    background-color: #f7f7f7;
    border-radius: 10px;
  }

  &__remove {

    &-text {
      font-size: 14px;
      line-height: 16px;
    }

    &-icon {
      display: none;
    }
  }

  @media (max-width: 1100px) {

    &__photo-wrapper {
      width: 64px;
      height: 64px;
      margin-right: 7px;
    }

    &__gift {
      height: 18px;
      font-size: 14px;
      line-height: 19px;
    }

    &__price {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      color: #1a1a1a;
    }
  }

  @media (min-width: 1000px) and (max-width: 1100px) {

    &__count {
      padding: 12px 10px;
    }

    &__remove {

      &-text {
        display: none;
      }

      &-icon {
        display: block;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 1000px) {

    &__info {
      flex-grow: 1;
      padding-bottom: 15px;
    }

    &__title {

      &_unavailable {
        max-width: calc(100% - 95px);
      }
    }

    &__photo-wrapper {
      margin-right: 10px;
      background-color: #f7f7f7;
      border-radius: 16px;
    }

    &__photo {
      max-width: 44px;
      max-height: 44px;
    }

    &__count {
      flex-shrink: 0;
      width: 84px;
      padding: 9px 12px;
      border-radius: 10px;
    }

    &__bottom {
      display: block;
      margin-left: 0;

      &_unavailable {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        line-height: 11px;
      }
    }
  }
}
</style>
