import type CouponError from '~/type/order/CouponError'

export default function (el: { [key: string]: any } = {}): CouponError {
  return {
    assortment: el.ASSORTMENT,
    sale: el.DENOMINATION,
    orderSum: el.ORDER_SUM,
    isSingleUse: el.SINGLE_USE,
    expirationDate: el.VALIDITY,
  }
}
