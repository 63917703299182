<script setup lang="ts">
import icon from './asset/free-delivery.svg'
import iconWhite from './asset/free-delivery-white.svg'

interface Props {
  delivery: number
  totalPrice: number
  freeDelivery: number
}

const props = withDefaults(defineProps<Props>(), {
  delivery: 0,
  freeDelivery: 500,
})

const rangeDeliveryValue = computed(() => {
  if ((props.totalPrice / props.freeDelivery) * 100 > 100)
    return 100
  else
    return (props.totalPrice / props.freeDelivery) * 100
})
const freeDeliveryAvailable = computed(() => props.totalPrice >= props.freeDelivery)
</script>

<template>
  <div class="free-delivery-range">
    <div class="free-delivery-range__content">
      <div class="free-delivery-range__img-wrapper" :class="{ 'free-delivery-range__img-wrapper_free': freeDeliveryAvailable }">
        <img
          class="free-delivery-range__img"
          :src="freeDeliveryAvailable ? iconWhite : icon"
          alt="Бесплатная доставка"
        >
      </div>
      <div class="free-delivery-range__info">
        <p class="free-delivery-range__title">
          <template v-if="!freeDeliveryAvailable">
            До бесплатной доставки:
            {{ freeDelivery - totalPrice }}₽
          </template>
          <template v-else>
            Доставка бесплатная
          </template>
        </p>
        <p class="free-delivery-range__subtitle">
          <template v-if="!freeDeliveryAvailable">
            Доставка
            {{ delivery }}₽
          </template>
        </p>
      </div>
    </div>
    <div class="free-delivery-range__size">
      <div class="free-delivery-range__value" :style="{ width: `${rangeDeliveryValue}%` }" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.free-delivery-range {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #f8f8f8;
  border-radius: 20px;

  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 8px;

    &_free {
      background-color: #5EE05B;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__info {
    margin-top: 3px;
  }

  &__size {
    position: relative;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    overflow: hidden;
    background-color: transparent;
    border-radius: 5px;
  }

  &__value {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 3px;
    background-color: #68F965;
    border-radius: 5px;
  }

  &__title {
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #181818;
  }

  &__subtitle {
    font-size: 12px;
    line-height: 12px;

    color: #818181;
  }
  @media (max-width: 1000px) {

    &__subtitle {
      margin: 7px 0px 0px;
    }
  }
}
</style>
